import api from "../../../utils/Api";

// Action type
export const UPDATE_IMPORT_DATA = 'UPDATE_IMPORT_DATA';
export const UPDATE_IMPORT_PROGRESS = "UPDATE_IMPORT_PROGRESS"

// Action Creator
export const updateMemberData = (importData) => ({
    type: UPDATE_IMPORT_DATA,
    payload: importData,
  });

export const updateImportProgress = (progress) => ({
    type: UPDATE_IMPORT_PROGRESS,
    payload: progress,
  });

// Thunk
export const importMembersThunk = (importData, role, newList, currentList) => async (dispatch) => {
    try {
      dispatch(updateImportProgress(0));
      dispatch(updateMemberData(importData));
      const response = await api.post('/member/import/', {
        members: importData,
        role,
        new_list: newList,
        member_list: currentList.value,
        unparsed_data: importData,
      });
      if (response.ok) {
        dispatch(updateImportProgress(response.data.importedDataCount));
        return response
      } else {
        return { ok: false, error: "Invalid CSV file, please download the example file and try again"};
      }
    } catch (error) {
      const errorMessage = error.data?.error || "Invalid CSV file, please download the example file and try again";
      const missingFields = error.data?.missing_fields || [];
     return { ok: false, data: { error: errorMessage, missing_fields: missingFields } };
    }
  };
