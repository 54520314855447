import axios from "axios";
import api from "../../../utils/Api";

export const GET_MESSAGES = "messages/GET";
export const GET_MESSAGE = "message/GET";
export const CLEAR_MESSAGES = "messages/CLEAR";
export const GET_MAILING_LISTS = "mailingLists/GET";
export const GET_SELECTED_MAILING_LIST = "selectedMailingLists/GET";;
export const UPDATE_MAILING_LIST = "mailingList/PUT";

export const getMessage = (message) => {
  return {
    type: GET_MESSAGE,
    message,
  };
};

export const getMessages = (messages) => {
  return {
    type: GET_MESSAGES,
    messages,
  };
};

export const clearMessagesAction = () => {
  return {
    type: CLEAR_MESSAGES,
  };
};

// MallingList Acitons
export const getMailingListsAction = (mailingListsData) => {
  return {
    type: GET_MAILING_LISTS,
    mailingListsData,
  };
};

export const selectMailingList = (mailingList) => ({
  type: GET_SELECTED_MAILING_LIST,
  mailingList
});

export const updateMailingListAction = (mailingList) => {
  return {
    type: UPDATE_MAILING_LIST,
    mailingList,
  };
};


export const getMessagesThunk = (path) => async (dispatch) => {
  const url = path === "inbox" ? "/message/" : "/message/sent/";
  const response = await api.get(url);
  if (response.ok) dispatch(getMessages(response.data));
};

export const getMessageThunk = (url, id) => async (dispatch) => {
  const response = await api.get(`/${url}/${id}/`);
  if (response.ok) dispatch(getMessage(response.data));
};

export const postMessageThunk = (payload) => async () => {
  const response = await api.post(`/message/`, payload);
  return response
};

export const getAttachmentS3SignedURL = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/message/upload-init/`, payload)
    if (response.ok) return response
  } catch (error) {
    throw error
  }
}

export const deleteMail = (path, message_id) => async () => {
  const url = path === "inbox" ? "/message/" : "/message/sent/";
  const response = await api.delete(`${url}${message_id}/`);
  return response;
};

export const sendHelpScoutMessage = (payload) => async () => {
  try {
    const response = await api.post('/message/help/', payload)
    if (response.ok) return response
    else throw new Error('There was an issue processing your request. Please try again later.')
  } catch (e) {
    throw e
  }
}

export const getContacts = (filter) => {
  return async (dispatch) => {
    return api
      .get(`/message/contact/`, { include_subscribers: true })
      .then((response) => {
        if (response.ok) {
          let contacts = Object.values(response.data);

          dispatch({
            type: "GET_CONTACTS",
            contacts,
          });
        } else {
        }
      });
  };
};

export const getProjects = (filter) => {
  return async (dispatch) => {
    return api.get(`/organization/project/`, {}).then((response) => {
      if (response.ok) {
        dispatch({
          type: "GET_PROJECTS",
          projects: response.data,
        });
      } else {
      }
    });
  };
};

// ** UPDATE Mails
// export const updateMails = (emailIds, dataToUpdate) => {
//   return (dispatch, getState) => {
//     return axios
//       .post("/views/messages/update-emails", { emailIds, dataToUpdate })
//       .then((res) => {
//         dispatch({
//           type: "UPDATE_MAILS",
//           emailIds,
//           dataToUpdate,
//           data: res.data,
//         });
//         dispatch(getMails(getState().email.params));
//       });
//   };
// };

// ** Update Mail Label
// export const updateMailLabel = (emailIds, label) => {
//   return (dispatch, getState) => {
//     return axios
//       .post("/views/messages/update-emails-label", { emailIds, label })
//       .then((res) => {
//         dispatch({ type: "UPDATE_MAIL_LABEL", data: res.data });
//         dispatch(getMails(getState().email.params));
//       });
//   };
// };

// ** GET Next/Prev mail
export const paginateMail = (dir, emailId) => {
  return (dispatch) => {
    return axios
      .get("/views/messages/paginate-email", { params: { dir, emailId } })
      .then((res) => {
        dispatch({ type: "PAGINATE_MAIL", data: res.data });
      });
  };
};

// ** SELECT Mail
export const selectMail = (id) => (dispatch) =>
  dispatch({ type: "SELECT_MAIL", id });

// ** SELECT All Mails
export const selectAllMail = (val) => (dispatch) =>
  dispatch({ type: "SELECT_ALL_MAIL", val });

// ** RESET Selected Mails
export const resetSelectedMail = () => (dispatch) =>
  dispatch({ type: "RESET_SELECT_MAILS" });

// Malling List Thunks
  export const getMailingListsThunk = (params = {}) => async (dispatch) => {
    try {
      const response = await api.get("mailing-list/", params);
      dispatch(getMailingListsAction(response.data));
      return response.data;
      } catch (error) {
        throw error;
    }
  };

  export const createMailingListThunk = (payload, params) => async (dispatch) => {
    try {
      const response = await api.post("mailing-list/", payload);
      // Refetch the mailing lists with the passed in params, which will include the newest one.
      // This is so that if the user is on a page other than page 1, they will be led back to page one with data that should go on page 1.
      dispatch(getMailingListsThunk(params));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const updateMailingListThunk = (payload) => async (dispatch) => {
    try {
      const response = await api.put("mailing-list/", payload);
      dispatch(updateMailingListAction(response.data));
      return response.data;
    } catch (error) {
      throw error
    }
  };
