import { CLEAR_MESSAGES, GET_MESSAGE, GET_MESSAGES, GET_MAILING_LISTS, GET_SELECTED_MAILING_LIST, UPDATE_MAILING_LIST,} from "../../actions/messagesAction";

// ** Initial State
const initialState = {
  messages: [],
  contacts: [],
  projects: [],
  params: {},
  currentMessage: null,
  emailsMeta: {},
  selectedMails: [],
  mailingLists: {
    lists: [],
    total: 0
  },
};

const MessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case GET_MESSAGE:
      return {
        ...state,
        currentMessage: action.message
      }
    case "GET_CONTACTS":
      return {
        ...state,
        contacts: action.contacts,
      };

    // actions of mailing lists
    case GET_MAILING_LISTS:
      return {
        ...state,
        mailingLists: {
          lists: action.mailingListsData.mailing_lists,
          total: action.mailingListsData.total
        }
      };
    case GET_SELECTED_MAILING_LIST:
        return {
            ...state,
            selectedMailingList: action.mailingList
        };
    case UPDATE_MAILING_LIST:
      return {
        ...state,
        mailingLists: {
          ...state.mailingLists,
          lists: state.mailingLists.lists.map(ml => ml.pk === action.mailingList.mailing_list.pk ? action.mailingList.mailing_list : ml),
        }
      };
    default:
      return state;
  }
};

export default MessageReducer;
